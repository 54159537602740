import React, {Fragment, useEffect, useState} from "react";
import {Spinner}                              from "../dom/Spinner";
import {PersonSection}                        from "./PersonSection";
import {ElementsContext}                      from "../enums/ElementsContext";
import {FieldsetHeader}                       from "../dom/FieldsetHeader";
import {ElementActiveSection}                 from "../dom/ElementActiveSection";
import {Select}                               from "../dom/Select";
import {WishesComments}                       from "./WishesComments";
import {ErrorContainer}                       from "../dom/ErrorContainer";
import CreatableSelect                        from 'react-select/creatable';
import PropTypes                              from "prop-types";

const axios = require("axios").default;


export const WishesDetailView = props => {
    const [element, setElement] = useState(props.element || {});
    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
    });

    useEffect(() => {
    }, []);

    const setActiveStatus = (status) => {
        setElement({...element, inactive: status}, );
    };

    const saveActive = () => {
        setLoading(true);
        axios.put('/api/wishes/', {element: element},
        )
             .then((response) => {
                 setLoading(false);
             })
             .catch((error) => {
                 setErrors(errors => error.response.data.errors);
                 setLoading(false);
             });
    };

    if (isLoading) {
        return (<Spinner/>);
    }

    return (
        <Fragment>
            <PersonSection
                context={ElementsContext.WISHES}
                personId={element.person_id}/>

            <input name="backUrl"
                   type="hidden"
                   value={props.backUrl || ""}/>
            <input name="element[ID]"
                   type="hidden"
                   value={props.elementId || ""}/>
            <input name="element[inactive]"
                   type="hidden"
                   value={element.inactive || ""}/>
            <input name="element[person_id]"
                   type="hidden"
                   value={element.person_id || ""}/>
            <input name="time_insert"
                   type="hidden"
                   value={element.time_insert || ""}/>

            {errors.length > 0 ? <ErrorContainer arErrors={errors}/> : ""}

            <fieldset className="border-0">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <FieldsetHeader
                            text={"Заявка на обучение"}/>
                    </div>
                    <div className="col-12 col-md text-end">
                        <ElementActiveSection
                            onSetActiveStatus={setActiveStatus}
                            inactiveStatus={element.inactive}/>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-12 col-md-6">
                        <label className="display_block" htmlFor="program_id">Желаемая программа обучения</label>
                        <CreatableSelect
                            onChange={onProgramChange}
                            id={"program_id"}
                            name={"element[program_id]"}
                            placeholder="Желаемая программа обучения"
                            value={element.program || {ID: "", name: "Выбрать программу обучения"}}
                            options={programs}
                            getOptionLabel={option => option.name}
                            getOptionValue={option => option.ID}
                        />
                    </div>
                    <div className="form-group col-12 col-md-6">
                        <Select
                            onChange={handleChange}
                            isRequired={true}
                            id="adver_id"
                            name={"element[adver_id]"}
                            label={"Источник информации о центре"}
                            defaultOption={"Выбрать источник информации"}
                            options={adverts}
                            selected={element.adver_id || ""}/>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-12 col-md-6">
                        <input
                            id="current_comment"
                            name="element[current_comment]"
                            type="hidden"
                            value={element.current_comment || ""}/>

                        <WishesComments comments={element.array_of_comments || []}/>

                    </div>
                    <div className="form-group col-12 col-md-6">
                        <label className="display_block" htmlFor="comment">Добавить свои комментарии</label>
                        <textarea
                            onChange={handleChange}
                            className="form-control"
                            id="comment"
                            name="element[comment]"
                            value={element.comment || ""}/>
                        <small className="form-text text-muted">
                            Укажите в комментариях всю значимую информацию по контакту с желающим.
                            Предыдущие
                            комментарии см. в другом поле. Пользователь, дата и время будут записаны
                            автоматически.
                        </small>
                    </div>
                </div>

            </fieldset>

            <div className="btn_container">
                <button className="btn btn-primary"
                        type="submit"
                        name="submit"
                        value="Y">
                    Сохранить
                </button>
                <button
                    className="btn btn-secondary btn-cancel"
                    onClick={cancelBtnClick}>
                    отменить
                </button>
            </div>

        </Fragment>
    );
};

WishesDetailView.propTypes = {
    element: PropTypes.object,
};
