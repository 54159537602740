import {Pages}                 from "./Pages";
import React                   from "react";
import ReactDOM                from "react-dom";
import {WishesForm}            from "../components/WishesForm";
import {WishesListElementView} from "../components/WishesListElementView";
import {WishesDetailView}      from "../components/WishesDetailView";

export class WishesDetail extends Pages {

    constructor(params) {
        super(params);

        this.data = window.diaryJsData;

        ReactDOM.render(
            <WishesDetailView
                element={this.data.element}/>,
            document.getElementById('wishesDetail')
        );

    }

    initPageHandlers(params) {
        super.initPageHandlers(params);
    }
}
